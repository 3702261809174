import {
  Header,
  Container,
  Title,
  SubTitle,
  Images,
  ImagesItem,
  Content,
} from "../components/styles";
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby"; // to query for image data
import Accordion from "../components/accordion";
import SEO from "../components/seo";

// markup
const CupcakesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "cupcakes" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <SEO
        title="Cupcakes in Rugby"
        description="A variety of different cupcake flavours available to order in Rugby for birthdays, anniversaries, or any other special occasion."
      />
      <Header>
        <Container>
          <Title>Cupcakes</Title>
        </Container>
      </Header>
      <Container>
        <Content>
          <div>
            <SubTitle>Flavours</SubTitle>
            <Accordion
              open={true}
              title="Lemon"
              content="A favourite of The Stanley Bakes! A delicious lemon sponge, filled with lemon curd and topped with a lemon buttercream."
            />
            <Accordion
              open={true}
              title="Chocolate"
              content="A classic chocolate sponge, topped with smooth chocolate buttercream."
            />
            <Accordion
              open={true}
              title="Vanilla"
              content="A delightful vanilla sponge, topped with vanilla buttercream. Simple, but a real crowd-pleaser."
            />
          </div>
          <div>
            <SubTitle>Prices</SubTitle>
            <p>
              6 for £18
              <br />
              12 for £25
            </p>
            <p>Price includes the box for your cupcakes.</p>
            <p>
              Decorations can include sprinkles, dried flowers, meringue kisses
              and mini macarons. Additional charges may apply.
            </p>
            <p>
              We can accommodate requests for personalisation with enough
              notice. Please find out more on our order page.
            </p>
          </div>
        </Content>
        <Images>
          {data.allFile.edges.map((image) => (
            <ImagesItem
              fluid={image.node.childImageSharp.fluid}
              alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
              key={image.node.base.split(".")[0]}
            />
          ))}
        </Images>
      </Container>
    </React.Fragment>
  );
};

export default CupcakesPage;
