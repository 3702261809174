import React, { useState } from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
    border-bottom: 1px solid grey;
`;

const Title = styled.h3`
    font-size: 18px;
    margin: 0;
    padding: 10px;
`;

const Content = styled.div`
    max-height: ${({ open }) => (open ? "250px" : "0")};
    overflow: hidden;
    transition: 0.3s ease-in-out;
    color: #616161;

    p {
        margin: 0;
        padding: 0 10px 10px;
    }
`;

const Accordion = props => {

    const [open, setOpen] = useState(props.open);

    return (
        <Wrapper>
            <Title onClick={e => setOpen(!open)}>{props.title}</Title>
            {props.content ? (<Content open={open}>
                <p>{props.content}</p>
            </Content>) : null}
            
        </Wrapper>
    )
}

export default Accordion;